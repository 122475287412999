import { getCookie } from '../dbconnector/checktoken'



//server https
// const baseurl = "https://api.myshc.net/api"
// const baseUrlNonApi = "https://api.myshc.net/"

const baseurl = "https://myshc.net:9000/api"
const baseUrlNonApi = "https://myshc.net:9000/"


//monkiis apiserver
// const baseurl = "http://api.monkiis.be/api"
// const baseUrlNonApi = "http://api.monkiis.be/"
//monkiis apiserver
// const baseurl = "http://185.115.217.33:9000/api"
// const baseUrlNonApi = "http://185.115.217.33:9000/"

//michielpc
// const baseurl = "http://192.168.129.12:9000/api"
// const baseUrlNonApi = "http://192.168.129.12:9000/"




const getserverurl = () => {
  return baseUrlNonApi
}

// create - edit - update - get Hotel

const sendhoteldata = async (form) => {
  const Bearer = await getCookie("logintoken")
  const formdata = new FormData();
  formdata.append("file", form.Contract.value)
  if (form.Contract.value.length === 0) {
    return await fetch(baseurl + "/hotels/createhotel", {
      method: "POST",
      body: JSON.stringify({
        ...form
      }),
      headers: {
        "Content-type": "application/json",
        "Token": "Bearer " + Bearer,
      }
    });
  } else {
    const fileresponse = await fetch(baseurl + "/hotels/upload", {
      method: "POST",
      body: formdata,
    }).then(response => response.json()).then(result => {
      return result
    });
    const filename = fileresponse.filename;
    return await fetch(baseurl + "/hotels/createhotel", {
      method: "POST",
      body: JSON.stringify({
        ...form,
        Contract: filename
      }),
      headers: {
        "Content-type": "application/json",
        "Token": "Bearer " + Bearer,
      }
    });
  }
}

const updateHotelData = async (form, hotelid) => {
  const Bearer = await getCookie("logintoken")
  let response;
  if (form.Contract && form.Contract !== null && form.Contract.value) {
    const formdata = new FormData();
    formdata.append("file", form.Contract.value)
    const fileresponse = await fetch(baseurl + "/hotels/upload", {
      method: "POST",
      body: formdata,
    }).then(response => response.json()).then(result => {
      return result
    });
    const filename = fileresponse.filename;
    response = await fetch(baseurl + "/hotels/updatehotel/" + hotelid, {
      method: "POST",
      body: JSON.stringify({
        ...form,
        Contract: filename
      }),
      headers: {
        "Content-type": "application/json",
        "Token": "Bearer " + Bearer,
      }
    });
  } else {
    response = await fetch(baseurl + "/hotels/updatehotel/" + hotelid, {
      method: "POST",
      body: JSON.stringify({
        ...form
      }),
      headers: {
        "Content-type": "application/json",
        "Token": "Bearer " + Bearer,
      }
    });
  }
  return response
}

const getallhotels = async () => {
  const Bearer = await getCookie("logintoken")

  const hotel = await fetch(baseurl + "/hotels", {
    method: "GET",
    headers: {
      "Token": "Bearer " + Bearer,
    }
  }).then(response => response.json()).then(result => {
    return result
  })
  return hotel;
}

const gethotel = async (id) => {
  const Bearer = await getCookie("logintoken")
  const hotel = await fetch(baseurl + "/hotels/" + id, {
    method: "GET",
    headers: {
      "Token": "Bearer " + Bearer,
    }
  }).then(response => { return response.json() })
  return hotel;
}

const gethotelcleaners = async (id) => {
  const Bearer = await getCookie("logintoken")
  const employee = await fetch(baseurl + "/employee", {
    method: "GET",
    headers: {
      "Token": "Bearer " + Bearer,
    }
  }).then(response => response.json())
  return employee;
}

// end function 'Hotel'


// load a pdf
const getcontract = (pdfname) => {
  return baseUrlNonApi + pdfname;
}


// create - edit - update - get Employee data
const saveLinkedhotels = async (linkedhotels, id) => {
  const Bearer = await getCookie("logintoken")
  return await fetch(baseurl + "/employee/savehotels/" + id, {
    method: "POST",
    headers: {
      "Content-type": "application/json",
      "Token": "Bearer " + Bearer,
    },
    body: JSON.stringify({
      linkedhotels
    })
  })
}

const getLinkedhotels = async (id) => {
  const Bearer = await getCookie("logintoken")
  return await fetch(baseurl + "/employee/getlinkedhotels/" + id, {
    method: "GET",
    headers: {
      "Token": "Bearer " + Bearer,
    },
  })
}

const getMyLinkedhotels = async () => {
  const Bearer = await getCookie("logintoken")
  return await fetch(baseurl + "/employee/getmylinkedhotels/", {
    method: "GET",
    headers: {
      "Token": "Bearer " + Bearer,
    },
  })
}

const sendemployeedata = async (user) => {
  const Bearer = await getCookie("logintoken")
  const response = await fetch(baseurl + "/employee/create", {
    method: "POST",
    body: JSON.stringify({
      ...user
    }),
    headers: {
      "Content-type": "application/json",
      "Token": "Bearer " + Bearer,

    }
  });
  return response;

}

const updateEmployeedata = async (user) => {
  const Bearer = await getCookie("logintoken")

  const response = await fetch(baseurl + "/employee/" + user.id, {
    method: "put",
    body: JSON.stringify({
      ...user
    }),
    headers: {
      "Content-type": "application/json",
      "Token": "Bearer " + Bearer,
    }
  });
  return response

}

const getemployees = async () => {
  const Bearer = await getCookie("logintoken")
  const employee = await fetch(baseurl + "/employee", {
    method: "GET",
    headers: {
      "Token": "Bearer " + Bearer,
    }
  }).then(response => response.json())
  return employee;
}

//end functions 'Employee'

const login = async (form) => {
  const response = await fetch(baseurl + "/auth", {
    method: "POST",
    body: JSON.stringify({
      Emailaddress: form.email,
      Password: form.password,
    }),
    headers: {
      "Content-type": "application/json",
    }
  });
  return response;


}

const tokencall = async (token) => {
  return await fetch(baseurl + "/auth/" + token, {
    method: "get",
    headers: {
    }
  }).then(res => res.json()).then(body => body);

}

const checkinByHotelId = async (id) => {
  const Bearer = await getCookie("logintoken")
  const type = "work"
  return await fetch(baseurl + "/check/", {
    method: "POST",
    headers: {
      "Token": "Bearer " + Bearer,
      "Content-type": "application/json",
    },
    body: JSON.stringify({
      type: type,
      id
    }),
  })
}


const checkin = async (token, { latitude, longitude }) => {
  const type = "work"
  if (latitude && longitude) {
    return await fetch(baseurl + "/check/", {
      method: "POST",
      headers: {
        "Token": "Bearer " + token,
        "Content-type": "application/json",
      },
      body: JSON.stringify({
        type: type,
        cords: {
          latitude,
          longitude
        },
      }),
    })
  }

}
const checkout = async (type, { latitude, longitude }) => {
  const Bearer = await getCookie("logintoken")
  return await fetch(baseurl + "/check/checkout", {
    method: "POST",
    headers: {
      "Token": "Bearer " + Bearer,
      "Content-type": "application/json",
    },
    body: JSON.stringify({
      type: type,
      cords: {
        latitude,
        longitude
      }
    })
  })
}

const retrieveCheckinData = async (day) => {
  const Bearer = await getCookie("logintoken")
  day = new Date(day).toISOString().slice(0, 10).replace('T', ' ')
  console.log(day)
  console.log(Bearer)
  if (Bearer !== "" && Bearer !== null) {
    console.log(Bearer)
    return await fetch(baseurl + "/check/day/" + day, {
      method: "GET",
      headers: {
        "Token": "Bearer " + Bearer,
      }
    }).then(response => response.json()).then(result => {
      return result
    });
  }

}

const pause = async ({ latitude, longitude }) => {
  const Bearer = await getCookie("logintoken")
  return await fetch(baseurl + "/check/pausein", {
    method: "POST",
    headers: {
      "Token": "Bearer " + Bearer,
      "Content-type": "application/json",
    },
    body: JSON.stringify({
      cords: { latitude, longitude }
    }),
  })
}

const pauseout = async ({ latitude, longitude }) => {
  const Bearer = await getCookie("logintoken")
  return await fetch(baseurl + "/check/pauseout", {
    method: "POST",
    headers: {
      "Token": "Bearer " + Bearer,
      "Content-type": "application/json",
    },
    body: JSON.stringify({
      cords: { latitude, longitude }
    }),
  })
}

const pauseoutById = async (id) => {
  const Bearer = await getCookie("logintoken")
  return await fetch(baseurl + "/check/pauseout", {
    method: "POST",
    headers: {
      "Token": "Bearer " + Bearer,
      "Content-type": "application/json",
    },
    body: JSON.stringify({
      id
    }),
  })
}

const getCheckinOrOutTime = async () => {
  const Bearer = await getCookie("logintoken")
  return await fetch(baseurl + "/check/inout", {
    method: "GET",
    headers: {
      "Token": "Bearer " + Bearer,
    }
  }).then(response => response.json()).then(result => {

    return result
  });

}

const myData = async (id = null) => {
  const Bearer = await getCookie("logintoken")
  return await fetch(baseurl + "/employee/my-data", {
    method: "GET",
    headers: {
      "Token": "Bearer " + Bearer,
      "id": id
    }
  }).then(response => response.json()).then(result => {
    return result
  });
}

const daystats = async () => {

  const Bearer = await getCookie("logintoken")

  return await fetch(baseurl + "/check/daystats", {
    method: "GET",
    headers: {
      "Token": "Bearer " + Bearer,
    }
  }).then(response => response.json()).then(result => {
    return result
  });


}
// create - edit - get rooms

const getRooms = async (id) => {
  const Bearer = await getCookie("logintoken")
  const kamers = await fetch(baseurl + "/kamer/" + id, {
    method: "GET",
    headers: {
      "Token": "Bearer " + Bearer,
    }
  }).then(response => { return response.json() })
  return kamers;
}

const createroom = async (roomnumer, kamertypid, hotelid) => {

  const Bearer = await getCookie("logintoken")
  return await fetch(baseurl + "/kamer", {
    method: "POST",
    headers: {
      "Token": "Bearer " + Bearer,
      "Content-type": "application/json",
    },
    body: JSON.stringify({ roomnumer, kamertypid, hotelid }),
  });
}


const editkamer = async (id, type, hotelid) => {

  const Bearer = await getCookie("logintoken")
  return await fetch(baseurl + "/kamer/" + id, {
    method: "PUT",
    body: JSON.stringify({
      Type: type,
      hotelid
    }),
    headers: {
      "Content-type": "application/json",
      "Token": "Bearer " + Bearer,
    }
  });

}

const deleteRoom = async (id, hotelid) => {
  console.log(id, hotelid)

  const Bearer = await getCookie("logintoken")
  return await fetch(baseurl + "/kamer/delete/" + id, {
    method: "POST",
    body: JSON.stringify({
      hotelid
    }),
    headers: {
      "Content-type": "application/json",
      "Token": "Bearer " + Bearer,
    }
  });
}
// end functions 'Rooms'

// create - edit - get roomtypes


const getRoomsTypes = async (id) => {
  const Bearer = await getCookie("logintoken")
  //console.log(Bearer)

  const kamertypes = await fetch(baseurl + "/kamerType/" + id, {
    method: "GET",
    headers: {
      "Token": "Bearer " + Bearer,
    }
  }).then(response => { return response.json() })
  return kamertypes;
}


const createroomtype = async (roomtypename, hotelid) => {

  const Bearer = await getCookie("logintoken")
  return await fetch(baseurl + "/kamerType", {
    method: "POST",
    headers: {
      "Token": "Bearer " + Bearer,
      "Content-type": "application/json",
    },
    body: JSON.stringify({ roomtypename, hotelid }),
  });
}

const deleteRoomtype = async (id) => {

  const Bearer = await getCookie("logintoken")
  return await fetch(baseurl + "/kamertype/" + id, {
    method: "DELETE",
    headers: {
      "Content-type": "application/json",
      "Token": "Bearer " + Bearer,
    }
  });
}

const editkamerType = async (id, name, hotelid) => {

  const Bearer = await getCookie("logintoken")
  return await fetch(baseurl + "/kamerType/" + id, {
    method: "PUT",
    body: JSON.stringify({
      Naam: name,
      hotelid: hotelid
    }),
    headers: {
      "Content-type": "application/json",
      "Token": "Bearer " + Bearer,
    }
  });
}
// end functions 'roomtypes'

const getMyNotifications = async () => {
  const Bearer = await getCookie("logintoken")
  return await fetch(baseurl + "/notification", {
    method: "GET",
    headers: {
      "Token": "Bearer " + Bearer,
    }
  });

}

const getMyNotificationCount = async () => {
  const Bearer = await getCookie("logintoken")
  return await fetch(baseurl + "/notification/count", {
    method: "GET",
    headers: {
      "Token": "Bearer " + Bearer,
    }
  });

}

const uploadEmployeeContract = async (id, file) => {
  const formdata = new FormData();
  formdata.append("file", file)
  const Bearer = await getCookie("logintoken")
  return await fetch(baseurl + "/employee/upload/" + id, {
    method: "POST",
    headers: {
      "Token": "Bearer " + Bearer,
    },
    body: formdata
  });

}

const getciaw = async (day = new Date()) => {
  day = day = new Date(day).toISOString().slice(0, 10).replace('T', ' ')
  const Bearer = await getCookie("logintoken")
  return await fetch(baseurl + "/ciaw/" + day, {
    method: "GET",
    headers: {
      "Token": "Bearer " + Bearer,
    }
  }).then(response => response.json().then(body => {
    return body
  }))
}


// create - edit - get cleaningtasks
const saveCleaningtask = async (form) => {
  const Bearer = await getCookie("logintoken")
  return await fetch(baseurl + "/cleaningtask/create", {
    method: "POST",
    body: JSON.stringify({
      ...form
    }),
    headers: {
      "Token": "Bearer " + Bearer,
      "Content-type": "application/json",
    }
  })
}

const getCleaningtasks = async (id) => {
  const Bearer = await getCookie("logintoken")
  return await fetch(baseurl + "/cleaningtask/" + id, {
    method: "GET",
    headers: {
      "Token": "Bearer " + Bearer,
    }
  })
}


const editCleaningTask = async (id, taskDescription, taskDuration) => {
  const Bearer = await getCookie("logintoken")
  return await fetch(baseurl + "/cleaningtask/" + id, {
    method: "PUT",
    body: JSON.stringify({
      taskDescription: taskDescription,
      taskDuration: taskDuration
    }),
    headers: {
      "Content-type": "application/json",
      "Token": "Bearer " + Bearer,
    }
  });

}
const deleteCleaningTask = async (id) => {
  const Bearer = await getCookie("logintoken")
  return await fetch(baseurl + "/cleaningtask/" + id, {
    method: "DELETE",
    headers: {
      "Content-type": "application/json",
      "Token": "Bearer " + Bearer,
    }
  });
}

const savelinkedTasks = async (id, body) => {
  console.log(id, body)
  const Bearer = await getCookie("logintoken")
  return await fetch(baseurl + "/cleaningtypes/updateTasks/" + id, {
    method: "POST",
    headers: {
      "Content-type": "application/json",
      "Token": "Bearer " + Bearer,
    },
    body: JSON.stringify(body)
  })
}
const getlinkedtasks = async (id) => {
  console.log(id)
  const Bearer = await getCookie("logintoken")
  return await fetch(baseurl + "/cleaningtypes/linkedtasks/" + id, {
    method: "GET",
    headers: {
      "Token": "Bearer " + Bearer,
    }
  })
}

// end cleaningtasks

// create - edit and get cleaningtypes

const saveCleaningtype = async (form) => {
  const Bearer = await getCookie("logintoken")
  return await fetch(baseurl + "/cleaningtypes/create", {
    method: "POST",
    body: JSON.stringify({
      ...form
    }),
    headers: {
      "Token": "Bearer " + Bearer,
      "Content-type": "application/json",
    }
  })
}

const getCleaningtypes = async (id) => {
  console.log(id)
  const Bearer = await getCookie("logintoken")
  return await fetch(baseurl + "/cleaningtypes/" + id, {
    method: "GET",
    headers: {
      "Token": "Bearer " + Bearer,
    }
  })
}


const editCleaningType = async (id, description, duration, roomtype_id) => {
  const Bearer = await getCookie("logintoken")
  return await fetch(baseurl + "/cleaningtypes/" + id, {
    method: "PUT",
    body: JSON.stringify({
      description: description,
      duration: duration,
      roomtype_id: roomtype_id
    }),
    headers: {
      "Content-type": "application/json",
      "Token": "Bearer " + Bearer,
    }
  });

}

const deleteCleaningType = async (id) => {
  const Bearer = await getCookie("logintoken")
  return await fetch(baseurl + "/cleaningtypes/" + id, {
    method: "DELETE",
    headers: {
      "Content-type": "application/json",
      "Token": "Bearer " + Bearer,
    }
  });

}
// end cleaning types

// this function checks the version running on the client against the version deployed on the server.
// if different the clientversion is updated
const getversion = async () => {
  return await fetch(baseurl + "/check/version", {
    method: "GET",
    headers: {

    }
  }).then(res => res.json())
}

const sendPassReset = async (email) => {
  fetch(baseurl + "/password/reset-link", {
    method: "POST",
    headers: {
      "Content-type": "application/json",
    },
    body: JSON.stringify({
      email
    })
  })
}


export {
  sendhoteldata, getallhotels, gethotel,
  getcontract, sendemployeedata, getemployees,
  login, tokencall, checkin,
  retrieveCheckinData, checkout, getCheckinOrOutTime,
  pause, myData, daystats,
  updateHotelData, getRooms, getRoomsTypes,
  getserverurl, createroom, createroomtype,
  editkamer, deleteRoom, deleteRoomtype, updateEmployeedata,
  editkamerType, getMyNotifications, getMyNotificationCount,
  uploadEmployeeContract, getciaw, pauseout,
  saveLinkedhotels, getLinkedhotels, checkinByHotelId,
  pauseoutById, saveCleaningtask, getCleaningtasks, editCleaningTask, deleteCleaningTask,
  saveCleaningtype, getCleaningtypes, editCleaningType, deleteCleaningType,
  getversion, sendPassReset, savelinkedTasks, getlinkedtasks, getMyLinkedhotels,gethotelcleaners
}

import hotelcss from './css/hotelhotellist.module.css';
import React, { createElement } from "react"
import { useNavigate } from 'react-router-dom'
import { useParams } from "react-router-dom";
import { useState } from 'react';
import { useEffect } from 'react';
import { setErrorMessage } from "../tools/errorhandling";
import {
    myData, sendhoteldata, getallhotels, gethotel,
    getRooms, getRoomsTypes,
    getserverurl, saveCleaningtask, getCleaningtasks, getCleaningtypes, getlinkedtasks, getMyLinkedhotels, gethotelcleaners
} from "../dbconnector/connector";

import './css/tabs.css';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { MdCleaningServices } from "react-icons/md";
import { PiUser } from "react-icons/pi";
import Button from 'react-bootstrap/Button';
import { MdEdit, MdCheck } from "react-icons/md";
import { CgCloseR } from "react-icons/cg";



const extraFunctie = () => {

}



const AssignCleaningTasks = (props) => {


    const t = props.translation
    const [Edit, setEdit] = useState(true);
    const [linkedhotels, setLinkedHotels] = useState({});
    const [hotelCleaners, setHotelCleaners] = useState({});
    const [tabs, setTabs] = useState([]);
    const [tabpanels, setTabPanels] = useState([]);
    const [createAssignmentdiv, setCreateAssignmentdiv] = useState([]);
    const [creatDefaultAssignmentdiv, setCreateDefaultAssignmentdiv] = useState([]);
    const [cleaningtaskstoassignarray, setcleaningtaskstoassignarray] = useState([]);
    const [hotelcleanersarray, sethotelcleanersarray] = useState([]);
    const [hotelcleaners, sethotelcleaners] = useState([]);
    let { id } = useParams();
    const navigate = useNavigate()


    useEffect(() => {
        try {
            setLinkedHotels({})
            const response = getMyLinkedhotels()
            response.then(res => {
                if (res.status === 200) {
                    res.json().then(linkedhotels => {
                        setLinkedHotels(linkedhotels)
                    })
                } else {
                    setErrorMessage(res.statusText)
                }
            })
        } catch (e) {
            setErrorMessage("Error While retrieving user data")
        }
    }, []);

    useEffect(() => {
        buildtabs()

    }, [linkedhotels]);

    useEffect(() => {
        if (document.getElementById("cleaningtype") != null)
            showAssignedCleaningTasks(document.getElementById("cleaningtype").value);
    }, [createAssignmentdiv]);

    const buildtabs = () => {
        setTabs([])
        setTabPanels([])
        console.log(linkedhotels)
        if (linkedhotels.length > 0) {
            linkedhotels.forEach(hotel => {
                setTabs(pre => [...pre,
                <Tab id={hotel.hotel_Id}>{hotel.Name}</Tab>
                ])
                setTabPanels(pre => [...pre,
                <TabPanel>
                    <div className="tabpanel">

                        <div className="RoomTabs">
                            {buildkamerpanels(hotel.kamers, hotel.hotel_Id)}
                        </div>
                        <br></br>
                        <Button  id="CreatedefaultCT" className="Savebutton btn btn-primary margin-right lightgreenTextColor" onClick={() => CreateDefaultCleaningassignment(hotel.kamers[0],hotel.hotel_Id)}>create default cleaningtask</Button>
                    </div>
                </TabPanel>
                ])
            })
        }
    }

    const buildkamerpanels = (kamers, hotel_Id) => {
        document.getElementById("assignmentdetails").style.display = "none"
        document.getElementById("coverAll").style.display = "none"
        let kamerpanels = [];
        kamers.forEach(kamer => {
            if (kamer.kamerNummer != "No rooms") {
                kamerpanels.push(
                    <Button className={hotelcss.CleaningTypeButton} onClick={() => Createcleaningassignment(kamer, hotel_Id)}><center>
                        <div>{kamer.kamerNummer}</div>
                        <div className='kamertypenaam'>{kamer.kamerTypeNaam}</div>
                        <div ><PiUser />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<MdCleaningServices /></div>
                    </center>
                    </Button>
                )
            } else {
                kamerpanels.push(
                    <div className="NoRoomsLinked"><center>
                        No rooms have been assigned to this hotel
                    </center>
                    </div>)
            }

        });
        return kamerpanels
    }

    const handleEdit = () => {
        setEdit(false)
    }
    const handleCancel = () => {
        document.getElementById("assignmentdetails").style.display = "none"
        document.getElementById("coverAll").style.display = "none"
    }
    //handlCreateAssignment
    const handlCreateAssignment = () => {
        document.getElementById("assignmentdetails").style.display = "none"
        document.getElementById("coverAll").style.display = "none"
    }

    const showAssignedCleaningTasks = async (cleaningtypeid) => {
        const targetdiv = document.getElementById("linkedcleaningtasks");
        const response = await getlinkedtasks(cleaningtypeid);
        if (response.status === 200) {
            const body = await response.json()
            if (targetdiv != null)
                targetdiv.innerHTML = "";
            body.items.forEach((item, index) => {
                const LI = document.createElement("li")
                LI.innerText = item.taskDescription
                LI.className = "ListItemCleaningTasks"
                if (targetdiv != null)
                    targetdiv.appendChild(LI)
            })
        }
        return cleaningtaskstoassignarray
    }

    const BuildCleaningtypes = (cleaningTypeDescription) => {
        let cleaningtypes = [];
        if (cleaningTypeDescription.length > 0) {
            cleaningTypeDescription.forEach(cleaningtype => {
                cleaningtypes.push(
                    <option value={cleaningtype.id} >{cleaningtype.description}</option>
                )
            })
        }
        return cleaningtypes
    }

    useEffect(() => {
        sethotelcleanersarray([]);
        console.log('hotelcleaners:', hotelcleaners.length)
        if (hotelcleaners.length > 0) {
            hotelcleaners.forEach((item, index) => {
                sethotelcleanersarray(pre => [...pre,
                <option id={item.nr_wkn}>
                    {item.achternaam}, {item.voornaam}
                </option>
                ])
            })
        }
    }, [hotelcleaners]);

    const GetHotelCleaners = (HotelId) => {
        if (HotelId) {
            gethotelcleaners(HotelId).then(res => {
                console.log("gethotelcleaners ", res)
                if (res.status === 200) {
                    sethotelcleaners(res.items)
                } else {
                    setErrorMessage(res.statusText)
                }
            })
        }
    }

    const CreateDefaultCleaningassignment = (hotel_Id) => {
        setCreateAssignmentdiv([])
        setCreateDefaultAssignmentdiv([])
        GetHotelCleaners(hotel_Id);
        setCreateDefaultAssignmentdiv(pre => [
            <div>
                <div className='formcaption'><p>::: Maak een kuistaak aan :::</p> <CgCloseR className='closer' onClick={handleCancel}  /></div>
                <div className="AssignCleaningform">
                    <label className='labelhotel'>
                        Locatie:
                    </label>
                    <input id="locatie"  name="locatie" className="doublegrid" type='text'>
                    </input>
                    <label className='labelhotel'>
                        taken:
                    </label>
                    <textarea id="taken" name="taken" className="doublegrid" type='text'>
                    </textarea>
                    <label className='labelhotel'>
                        tijd [min]:
                    </label>
                    <input id="extratijd" name="extratijd" className="doublegrid" type='number'>
                    </input>

                    <label className='labelhotel'>
                        Medewerker:
                    </label>
                    <select id="Cleaner" name="Name" className="doublegrid" type='text' >
                    {hotelcleanersarray}
                    </select>
                    <div id="saveDiv" className="two_Buttons doublegrid">
                        <Button type="submit" id="saveButton" className="Savebutton" onClick={handlCreateAssignment}>Save</Button>
                        <Button type="button" id="cancelButton" className="Savebutton" onClick={handleCancel} >Cancel</Button>
                    </div>
                </div>
                </div>])
        document.getElementById("assignmentdetails").style.display = "block"
        document.getElementById("coverAll").style.display = "block"
    }
    


    const Createcleaningassignment = (kamer, hotel_Id) => {
        setCreateAssignmentdiv([])
        setCreateDefaultAssignmentdiv([])
        GetHotelCleaners(hotel_Id);
        setCreateAssignmentdiv(pre => [
            <div>
                <div className='formcaption'><p>::: Maak een kuistaak aan :::</p><CgCloseR className='closer' onClick={handleCancel}  /></div>

                <div className="AssignCleaningform">
                    <label className='labelhotel'>
                        Kamer nr:
                    </label>
                    <input id="roomnr" value={kamer.kamerNummer} name="Name" className="doublegrid" type='text' disabled>
                    </input>
                    <label className='labelhotel'>
                        Kamer type:
                    </label>
                    <input id="roomtype" value={kamer.kamerTypeNaam} name="Name" className="doublegrid" type='text' disabled>
                    </input>
                    <label className='labelhotel'>
                        kuistype:
                    </label>
                    <select id="cleaningtype" name="Name" className="doublegrid" type='text' onChange={(e) => showAssignedCleaningTasks(e.target.value)}>
                        {BuildCleaningtypes(kamer.cleaningTypeDescription)}
                    </select>
                    <label className='labelhotel'>
                        Standard taken:
                    </label>
                    <div id="cleaningtask" name="Name" className="selectCleaningTasks doublegrid" type='text'>
                        <ul id="linkedcleaningtasks" name="Name">
                        </ul>
                    </div>
                    <label className='labelhotel'>
                        extra taken:
                    </label>
                    <textarea id="cleaningtype_add" name="Name" className="doublegrid" type='text'>
                    </textarea>
                    <label className='labelhotel'>
                        meertijd [min]:
                    </label>
                    <input id="roomnr" name="Name" className="doublegrid" type='number'>
                    </input>

                    <label className='labelhotel'>
                        Medewerker:
                    </label>
                    <select id="Cleaner" name="Name" className="doublegrid" type='text' >
                        {hotelcleanersarray}
                    </select>
                    <div id="saveDiv" className="two_Buttons doublegrid">
                        <Button type="submit" id="saveButton" className="Savebutton" onClick={handlCreateAssignment}>Save</Button>
                        <Button type="button" id="cancelButton" className="Savebutton" onClick={handleCancel} >Cancel</Button>
                    </div>
                </div></div>])
        // BuildCleaners(kamer.HotelId)
        document.getElementById("assignmentdetails").style.display = "block"
        document.getElementById("coverAll").style.display = "block"
    }

    return (
        <>
            <div id="main">
                <header>
                    <div id="errordiv" className='error'></div>
                </header>
                <div id="content">
                    <form className="form">
                        <label className='formtitle'>Assign Cleaning tasks</label>
                        <Tabs className="HotelTabs" id="taboverview">
                            <TabList id="tablisthoteloverview">
                                {tabs}
                            </TabList>

                            {tabpanels}

                        </Tabs>
                    </form>
                </div>
                <div id="coverAll" className="coverall">
                    <div id="assignmentdetails" className='assignmentdiv'>
                        {createAssignmentdiv}
                        {creatDefaultAssignmentdiv}
                    </div>
                </div>


            </div>
        </>
    )

}

export default AssignCleaningTasks